import * as React from 'react';
import Header from "../components/Header"
import HeroList from "../components/HeroList"
import Footer from '../components/Footer'
import HeroTitle from "../components/HeroTitle";
import NavBar from "../components/NavBar";
import { Helmet } from 'react-helmet';
import { useEffect } from "react";
import ogImage from '../img/ogimage.png'
import ReactGA from "react-ga4";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#0000',
  border: '2px solid #0000',
  pt: 4,
  px: 4,
  pb: 3,
  width: '320px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  ":focused": {
    borderColor: "#C52328",
    borderWidth: "2px"
  }
};



export default function Root() {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.send({ hitType: "pageview", page: "/", title: "Homepage" });
    // Iubenda
    (function (w, d) { var loader = function () { var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src = "https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s, tag); }; if (w.addEventListener) { w.addEventListener("load", loader, false); } else if (w.attachEvent) { w.attachEvent("onload", loader); } else { w.onload = loader; } })(window, document);

  }, [])
  return (
    <>
      <Helmet>
        <title>Home - Scuola guida Lugano</title>
        <meta name="description"
          content="La vera scuola guida di Lugano Da noi trovate tutti i corsi di cui avete bisogno Soccorritori, Samaritani, Sensibilizzazione, Corsi moto" />
        <meta property="og:title" content="Scuola Guida Lugano" />
        <meta property="og:description"
          content="La Scuola Guida Lugano offre corsi guida, sensibilizzazione, soccorritori, corsi moto." />
        <meta property="og:type" content="video.movie" />
        <meta property="og:url" content="https://www.scuolaguidalugano.ch" />
        <meta property="og:image" content={ogImage} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="mysterious-lowlands-94999-f3e66227fc81.herokuapp.com" />
        <meta property="twitter:url" content="https://mysterious-lowlands-94999-f3e66227fc81.herokuapp.com/" />
        <meta name="twitter:title" content="Scuola Guida Lugano" />
        <meta name="twitter:description"
          content="La Scuola Guida Lugano offre corsi guida, sensibilizzazione, soccorritori, corsi moto." />
        <meta name="twitter:image"
          content="https://res.cloudinary.com/napolinews-it/image/upload/v1687171007/sociali_jhna04.png" />
      </Helmet>

      <div className={'containment'} style={{ marginTop: '-3px' }}>
        <NavBar page={'home'} />
        <Header img={'video'} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ position: 'relative', textAlign: 'center' }}>

            <HeroTitle
              subtitle={'SCUOLA GUIDA LUGANO'}
              subtitleTwo={'LA PATENTE'}
              subtitleThree={'A PORTATA DI MANO'}
            />

            <div style={{ textAlign: 'center' }}>
              <div className={'subtitolone'}>
                Accompagniamo ogni passo <br /> del tuo percorso di guida con cura,<br /> dal primo giorno al successo finale.<br />
                Preparati a metterti alla guida<br /> con fiducia e serenità, solo con<br /> Scuola guida Lugano.
              </div>
            </div>

            <div
              style={{ margin: '4rem 0rem' }}
            >
            </div>
          </div>

          <div style={{ position: 'relative' }}>
            <HeroList />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
