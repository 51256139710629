import logo from "../img/logo.png";
import * as React from "react";

export default function HeroTitle(props) {
  return (
    <>
      <div className="cappello-container containment">
        <div className="cappello">
          {props.title}
        </div>
        <div className="sub-cappello">
          {props.subtitle} <br />
          <span>{props.subtitleTwo}</span><br />
          <span>{props.subtitleThree}</span><br />
        </div>
      </div>
    </>
  )
}
