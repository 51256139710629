import * as React from 'react';
import NavigationBar from "../components/NavigationBar";
import Header from "../components/Header"
import CtaButton from "../components/Buttons/CtaButton";
import FlexboxLayout from '../components/FlexboxLayout';
import Card from '../components/CardComponent'
import Cappello from '../components/CappelloComponent';
import TextComponent from '../components/TextComponent';
import Footer from '../components/Footer';

import Soccorritori_card from '../img/soccorritori_card.png'
import Sensibilizzazione_card from '../img/sensibilizazzione_card.png'
import Combo_card from '../img/combo_card.png'
import Lezioni from '../img/lezioni_card.png'
import HeaderImg from '../img/header_combo.jpeg'
import {Helmet} from "react-helmet";
import logo from "../img/logo.svg";

export default function Root() {
  return (
    <>
      <Helmet>
        <title>Scuola guida Lugano - Lezioni Guida + Sensibilizzazione</title>
        <meta name="description" content="Accedi a Lezioni Guida + Sensibilizzazione della Scuola Guida Lugano"/>
        <meta property="og:title" content="Scuola Guida Lugano - Lezioni Guida + Sensibilizzazione"/>
        <meta property="og:description" content="Accedi a Lezioni Guida + Sensibilizzazione della Scuola Guida Lugano"/>
        <meta property="og:type" content="video.movie"/>
        <meta property="og:url" content="https://www.scuolaguidalugano.ch"/>
        <meta property="og:image"
              content="https://res.cloudinary.com/napolinews-it/image/upload/v1687171007/sociali_jhna04.png"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta property="twitter:domain" content="mysterious-lowlands-94999-f3e66227fc81.herokuapp.com"/>
        <meta property="twitter:url" content="https://mysterious-lowlands-94999-f3e66227fc81.herokuapp.com/"/>
        <meta name="twitter:title" content="Scuola Guida Lugano - Lezioni di Guida"/>
        <meta name="twitter:description" content="Accedi a Lezioni di Guida della Scuola Guida Lugano."/>
        <meta name="twitter:image"
              content="https://res.cloudinary.com/napolinews-it/image/upload/v1687171007/sociali_jhna04.png"/>
      </Helmet>
      <div className='pagetitle-container'>
        <Header title={["Lezioni + Sensibilizzazione"]} button={[<CtaButton/>]}
                loc="Lugano"
                headerimg={HeaderImg}
                img={Combo_card}
                card_title="Sconto"
                line1="CORSI"
                line2="10 Lezioni + Sensibilizazzione"
                line3="Il comodo pacchetto ”lezioni guida + sensibilizzazione” ti permette - dopo aver già conseguito il corso soccorritori e l’esame teorico di avviarti alla patente di guida in modo da essere seguito dalla A alla Z da un maestro di guida."
                line4="Lugano"
        />

        <div className='container-description'>
          <div className='triangle-container'>
            <div className='triangle-text'>
              <i></i>
              <TextComponent
                cose={'Il corso di Teoria della Circolazione (sensibilizzazione) dovrà, in particolare tramite la' +
                  ' formazione sulla circolazione stradale e di percezione dei pericoli, insegnare uno stile di' +
                  ' guida prudente e consapevole. I candidati che si impegnano a partecipare ad un corso di teoria' +
                  ' della circolazione, potranno partecipare al corso solo se in possesso di licenza per allievi' +
                  ' conducenti. Con questo comodo pacchetto, potrai continuare direttamente con le lezioni di guida.'}
                iscrizione={'Per iscriverti, clicca su ”iscriviti” e scorri la lista delle varie date. Una volta individuata la data più consona, procedi con l’inserimento dei dati. Una volta completato il processo, riceverai una e-mail di conferma. Ti aspettiamo a Lugano!'}
                costo={'CHF 1050'}
                durata={'corso di sensibilizzazione (valore 200 CHF) e 10 x 60 minuti di lezioni di guida (valore' +
                  ' 1000 CHF).'}
                button={'soccorritori'}
              />
            </div>
          </div>
        </div>
        {/*<div style={{width: '100%', marginBottom: '4rem'}}>*/}
        {/*  <Cappello cappello="Ti manca un corso?" subcappello="...Li trovi presso Scuola Guida Lugano"/>*/}
        {/*</div>*/}
        {/*<div className='flex-container-cards'>*/}
        {/*  <FlexboxLayout left={[*/}
        {/*    <a className={'no-margin'} href={`/corsi-soccorritori`}>*/}
        {/*      <Card*/}
        {/*        imageSrc={Soccorritori_card}*/}
        {/*        title="Obbligatorio"*/}
        {/*        line1="CORSI"*/}
        {/*        line2="Corso Soccorritori"*/}
        {/*        line3="II Corso Soccorritori per Allievi conducenti ti permette di apprendere le misure salva vita in caso di incidenti stradali, valutare la situazione e*/}
        {/*    adottare misure di primo soccorso."*/}
        {/*        line4="Lugano, Locarno, Mendrisio, Giubiasco"*/}
        {/*      />*/}
        {/*    </a>*/}
        {/*  ]}*/}
        {/* right={[*/}
        {/*   <a className={'no-margin'} href={`/sensibilizzazione`}>*/}
        {/*     <Card*/}
        {/*       imageSrc={Sensibilizzazione_card}*/}
        {/*       title="Obbligatorio"*/}
        {/*       line1="CORSI"*/}
        {/*       line2="Sensibilizazzione"*/}
        {/*       line3="Per poterti iscrivere all’esame di conducente pratico della categoria B, devi innanzitutto partecipare al corso di teoria della circolazione. Il corso è valido per due anni."*/}
        {/*       line4="Lugano"*/}
        {/*     />*/}
        {/*   </a>*/}
        {/* ]}/>*/}
        {/*</div>*/}
        {/*<div className='flex-container-cards'>*/}
        {/*  <FlexboxLayout left={[*/}
        {/*    <a className={'no-margin'} href={`/lezioni`}>*/}
        {/*      <Card*/}
        {/*        imageSrc={Lezioni}*/}
        {/*        title="10 Lezioni"*/}
        {/*        line1="CORSI"*/}
        {/*        line2="Lezioni di Guida"*/}
        {/*        line3="Il nostro obiettivo è quello di insegnarvi a guidare in modo dinamico e al tempo stesso sicuro."*/}
        {/*        line4="Lugano"*/}
        {/*      />*/}
        {/*    </a>*/}
        {/*  ]}*/}
        {/*                 right={[]}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      <Footer/>
    </>
  );
}
