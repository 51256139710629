import * as React from "react";
import * as ReactDOM from "react-dom/client";
import '@fontsource/public-sans';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import Root from "./routes/root";
import Soccorritori from "./routes/corsi-soccorritori";
import Sensibilizzazione from "./routes/sensibilizzazione";
import LezioniCombo from "./routes/lezioni_combo";
import Lezioni from "./routes/lezioni";
import Teorico from "./routes/teorico"
import Pratico from "./routes/pratico"
import Moto from "./routes/moto"
import ReactGA from "react-ga4";

ReactGA.initialize("G-3DRHS6NR4S");

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "/corsi-soccorritori",
    element: <Soccorritori />,
  },
  {
    path: "/teorico",
    element: <Teorico />,
  },
  {
    path: "/sensibilizzazione",
    element: <Sensibilizzazione />,
  },
  {
    path: "/lezioni-e-sensibilizzazione",
    element: <LezioniCombo />,
  },
  {
    path: "/lezioni",
    element: <Lezioni />,
  },
  {
    path: "/pratico",
    element: <Pratico />,
  },
  {
    path: "/moto",
    element: <Moto />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
