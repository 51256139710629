import * as React from 'react';
import {useEffect} from 'react';
import Header from "../components/Header"
import TextComponent from '../components/TextComponent';
import Footer from '../components/Footer';
import NavBar from "../components/NavBar";
import {Helmet} from "react-helmet";
import ogImage from "../img/ogimage.png";
import ReactGA from "react-ga4";


export default function Root() {
  useEffect(() => {
    window.scrollTo(0,0);
    ReactGA.send({
      hitType: "pageview",
      page: "/corsi-soccorritori",
      title: "Corsi soccorritori" });
  },[])
  return (
    <>
      <Helmet>
        <title>Corsi Soccorritori</title>
        <meta name="description" content="Accedi ai corsi soccorritori della Scuola Guida Lugano"/>

        <meta property="og:title" content="Scuola Guida Lugano - Soccorritori"/>
        <meta property="og:description"
              content="Accedi ai corsi soccorritori della Scuola Guida Lugano"/>
        <meta property="og:type" content="video.movie"/>
        <meta property="og:url" content="https://www.scuolaguidalugano.ch"/>
        <meta property="og:image" content={ogImage}/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta property="twitter:domain" content="mysterious-lowlands-94999-f3e66227fc81.herokuapp.com"/>
        <meta property="twitter:url" content="https://mysterious-lowlands-94999-f3e66227fc81.herokuapp.com/"/>
        <meta name="twitter:title" content="Scuola Guida Lugano - Soccorritori"/>
        <meta name="twitter:description" content="Accedi ai corsi soccorritori della Scuola Guida Lugano."/>
        <meta name="twitter:image"
              content="https://res.cloudinary.com/napolinews-it/image/upload/v1687171007/sociali_jhna04.png"/>
      </Helmet>
      <div className='footer-flex'>
        <div>
          <div className={'containment'}>
            <Header/>
            <NavBar page={'soccorritori'}/>
            <div className='container-description'>
              <div className='triangle-container'>
                <div className='triangle-text'>
                  <TextComponent
                    title={'CORSI SOCCORRITORI'}
                    cose={'II corso soccorritori per allievi conducenti ti permette di apprendere le misure salva' +
                      'vita in caso di incidenti stradali, valutare la situazione e adottare misure di primo soccorso.'}
                    iscrizione={'Per iscriverti, clicca su ”iscriviti”. Si aprirà la pagina di corsisoccorritori.ch' +
                      ' da' +
                      ' cui potrai iscriverti.'}
                    costo={'CHF 120'}
                    durata={'10 ore'}
                    button={'soccorritori'}
                  />
                </div>
              </div>
            </div>
          </div>

        </div>
        <Footer/>
      </div>
    </>
  );
}
