import logo from "../img/logo.png";
import * as React from "react";
import Button from "@mui/material/Button";
import chat_image from "../img/chat2.png";

export default function NavBar(props) {
  return (
    <>
      <div className="navbar containment">
        <div>
          <a href={`https://www.scuolaguidalugano.ch/`} className={props.page === 'home' ? 'activeblue' : null}>
            home
          </a>
          <a href={`https://www.scuolaguidalugano.ch/corsi-soccorritori`} className={props.page === 'soccorritori' ? 'activeblue' : null}>
            soccorritori
          </a>
          <a href={`https://www.scuolaguidalugano.ch/sensibilizzazione`} className={props.page === 'sensibilizzazione' ? 'activeblue' : null}>
            sensibilizzazione
          </a>
          <a href={`https://www.scuolaguidalugano.ch/lezioni`} className={props.page === 'lezioni' ? 'activeblue' : null}>
            lezioni guida
          </a>
          <a href={`https://www.scuolaguidalugano.ch/teorico`} className={props.page === 'teorico' ? 'activeblue' : null}>
            Esame teorico
          </a>
          <a href={`https://www.scuolaguidalugano.ch/pratico`} className={props.page === 'pratico' ? 'activeblue' : null}>
            Esame pratico
          </a>
          <a href={`https://www.scuolaguidalugano.ch/moto`} className={props.page === 'moto' ? 'activeblue' : null}>
            corsi moto
          </a>
          <a target={"_blank"} href={'https://wa.me/+41774442999'}>
            <Button
              variant="contained"
              className={'whatsapp'}
              size="large"
              style={{ marginRight: '0 !important'}}
            >  <img
              style={{ maxWidth: '24px', marginRight: '10px'}}
              src={chat_image}
              alt={'chat'}
            />
              Contattaci
            </Button>
          </a>
          <a style={{fontFamily: 'Open Sans', fontSize: '20px'}}>
            +41 77 444 29 99
          </a>
        </div>
      </div>
    </>
  )
}

