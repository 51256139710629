import * as React from 'react';
import fb_icon from '../img/fb_icon.png';
import logo from '../img/logo.png';

export default function Footer(props) {
  return (
    <>
      <div className="footer">
        <div className="footer-left">
          Scuola guida Lugano
          <br />
          Via Serafino Balestra 39a
          <br />
          6900 Lugano
          <div className='margin-privacy'>
            <a href="https://www.iubenda.com/privacy-policy/20651554" class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy
            </a>
            <span> </span>
            <a href="https://www.iubenda.com/privacy-policy/20651554/cookie-policy" class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a>
          </div>
        </div>
        <div className="footer-center flex flex-col">
          <a href='https://www.facebook.com/scuolaguidalugano'>
            <img src={fb_icon} />
          </a>
        </div>
        <div className="footer-right">
          Sasha
          <br />
          +41 77 444 29 99
          <br />
          info@scuolaguidalugano.ch
        </div>
        <div>
          <img className={'logo-footer'} src={logo} alt={'logo'} />
        </div>
      </div>
    </>
  );
}
