import * as React from 'react';
import Header from "../components/Header"
import TextComponent from '../components/TextComponent';
import Footer from '../components/Footer';
import NavBar from "../components/NavBar";
import {useEffect} from "react";
import {Helmet} from "react-helmet";
import ogImage from "../img/ogimage.png";
import ReactGA from "react-ga4";

export default function Root() {
  useEffect(() => {
    window.scrollTo(0,0);
    ReactGA.send({
      hitType: "pageview",
      page: "/sensibilizzazione",
      title: "Sensibilizzazione" });

  },[])
  return (
    <>
      <Helmet>
        <title>Sensibilizzazione</title>
        <meta name="description" content="Accedi alle Lezioni di Guida della Scuola Guida Lugano"/>

        <meta property="og:title" content="Sensibilizzazione"/>
        <meta property="og:description" content="Accedi a Sensibilizzaazione presso Scuola Guida Lugano"/>
        <meta property="og:type" content="video.movie"/>
        <meta property="og:url" content="https://www.scuolaguidalugano.ch/sensibilizzazione"/>
        <meta property="og:image" content={ogImage}/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta property="twitter:domain" content="mysterious-lowlands-94999-f3e66227fc81.herokuapp.com"/>
        <meta property="twitter:url" content="https://www.scuolaguidalugano.ch/sensibilizzazione"/>
        <meta name="twitter:title" content="Scuola Guida Lugano - Lezioni di Guida"/>
        <meta name="twitter:description" content="Accedi a Lezioni di Guida della Scuola Guida Lugano."/>
      </Helmet>
      <div className='footer-flex'>
        <div>
          <div className={'containment'}>
            <Header/>
            <NavBar page={'sensibilizzazione'}/>
            <div className='container-description'>
              <div className='triangle-container'>
                <div className='triangle-text'>
                  <i></i>
                  <TextComponent
                    title={'SENSIBILIZZAZIONE'}
                    cose={'Questo corso, noto anche come corso di teoria della circolazione, mira a aumentare la' +
                      ' consapevolezza dei futuri conducenti riguardo ai rischi del traffico. Il suo obiettivo principale è quello di promuovere una guida difensiva, insegnando come circolare in modo sicuro e prevenire gli incidenti stradali.'}
                    iscrizione={'Per iscriverti, clicca su ”iscriviti” e scorri la lista delle varie date. Una volta individuata la data più consona, procedi con l’inserimento dei dati. Una volta completato il processo, riceverai una e-mail di conferma. Ti aspettiamo a Lugano!'}
                    costo={'CHF 200'}
                    durata={'8 ore'}
                    button={'sensibilizzazione'}
                  />
                </div>
              </div>
            </div>

          </div>

        </div>
        <Footer/>
      </div>
    </>
  );
}
