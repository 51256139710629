import * as React from 'react';
import Header from "../components/Header"
import TextComponent from '../components/TextComponent';
import Footer from '../components/Footer';
import NavBar from "../components/NavBar";
import {Helmet} from "react-helmet";
import {useEffect} from "react";
import ogImage from "../img/ogimage.png";
import ReactGA from "react-ga4";

export default function Root() {
  useEffect(() => {
    window.scrollTo(0,0);
    ReactGA.send({ hitType: "pageview", page: "/lezioni", title: "Lezioni Guida" });
  },[])
  return (
    <>
      <Helmet>
        <title>Lezioni di Guida</title>
        <meta name="description" content="Accedi alle Lezioni di Guida della Scuola Guida Lugano"/>
        <meta property="og:title" content="Scuola Guida Lugano - Lezioni di Guida"/>
        <meta property="og:description" content="Accedi alle Lezioni di Guida della Scuola Guida Lugano"/>
        <meta property="og:type" content="video.movie"/>
        <meta property="og:url" content="https://www.scuolaguidalugano.ch"/>
        <meta property="og:image" content={ogImage}/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta property="twitter:domain" content="mysterious-lowlands-94999-f3e66227fc81.herokuapp.com"/>
        <meta property="twitter:url" content="https://mysterious-lowlands-94999-f3e66227fc81.herokuapp.com/"/>
        <meta name="twitter:title" content="Scuola Guida Lugano - Lezioni di Guida"/>
        <meta name="twitter:description" content="Accedi a Lezioni di Guida della Scuola Guida Lugano."/>
      </Helmet>
      <div className='footer-flex'>
        <div>
          <div className={'containment'}>
            <Header/>
            <NavBar page={'lezioni'}/>
            <div className='container-description'>
              <div className='triangle-container'>
                <div className='triangle-text'>
                  <i></i>
                  <TextComponent
                    title={'LEZIONI GUIDA'}
                    cose={'Acquisisci competenze di guida dinamica e sicura attraverso lezioni tenute da istruttori altamente qualificati, esperti e costantemente aggiornati. La nostra formazione di alta qualità ti supporterà lungo il percorso dei corsi obbligatori. Durante le sessioni di base, affronteremo gli itinerari che verranno affrontati durante l esame pratico.'}
                    iscrizione={'Per prenotare, clicca su ”contattaci” oppure telefona al numero +41 77 444 29 99. ' +
                      'Una' +
                      ' volta' +
                      ' individuata la data insieme, Sasha verrà a prenderti in macchina e potrai iniziare le lezioni' +
                      '. Ti aspettiamo a Lugano!'}
                    costo={'CHF 100'}
                    durata={'1 ora (60 minuti)'}
                    button={'lezioni'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </>
  );
}
