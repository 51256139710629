import * as React from 'react';
import TextComponent from '../components/TextComponent';
import Footer from '../components/Footer';
import NavBar from "../components/NavBar";
import {Helmet} from "react-helmet";
import ogImage from "../img/ogimage.png";

export default function Root() {
  return (
    <>
      <Helmet>
        <title>Teorico</title>
        <meta name="description" content="Accedi alle Lezioni di Guida della Scuola Guida Lugano"/>

        <meta property="og:title" content="Scuola Guida Lugano - Teorico"/>
        <meta property="og:description" content="Accedi a Teorico presso Scuola Guida Lugano"/>
        <meta property="og:type" content="video.movie"/>
        <meta property="og:url" content="https://www.scuolaguidalugano.ch/teorico"/>
        <meta property="og:image" content={ogImage}/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta property="twitter:domain" content="https://www.scuolaguidalugano.ch/teorico"/>
        <meta property="twitter:url" content="https://www.scuolaguidalugano.ch/teorico"/>
        <meta name="twitter:title" content="Scuola Guida Lugano - Lezioni di Guida"/>
        <meta name="twitter:description" content="Accedi a Lezioni di Guida della Scuola Guida Lugano."/>
      </Helmet>
      <div className='footer-flex'>
        <div>
          <div className={'containment'}>
            <NavBar page={'teorico'} />
            <div className='container-description'>
              <div className='triangle-container'>
                <div className='triangle-text'>
                  <i></i>
                  <TextComponent
                    title={'ESAME TEORICO'}
                    cose={'All esame si è ammessi unicamente se si presenta un documento di identità riconosciuto' +
                      ' (passaporto, carta d\'identità, permesso stranieri o licenza di condurre).'}
                    iscrizione={'Per iscriverti, clicca su ”iscriviti” e scorri la lista delle varie date. Una volta individuata la data più consona, procedi con l’inserimento dei dati. Una volta completato il processo, riceverai una e-mail di conferma. Ti aspettiamo a Lugano!'}
                    costo={'CHF 40'}
                    durata={'45 minuti'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
