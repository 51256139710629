import * as React from 'react';
import logo from "../img/logo.svg";
import MenuIcon from '@mui/icons-material/Menu';
import { useRef } from 'react';
import {Close} from "@mui/icons-material";
import {Button} from "@mui/material";
import chat_image from "../img/chat2.png";



export default function Root(props) {

  const modalRef = useRef(null);
  const iconRef = useRef(null);

  const openNav = (e) => {
    e.preventDefault();
    console.log(modalRef);
    console.log(iconRef);
    iconRef.current.style.display = 'none';
    modalRef.current.style.width = "100%";
  }

  const closeNav = (e) => {
    iconRef.current.style.display = 'block';
    modalRef.current.style.width = "0%";
  }

  return (
    <>

      <div ref={modalRef} className="overlay">
        <a className="closebtn" >
          <Close onClick={closeNav}/>
        </a>
        <div className="overlay-content">
          <a href="/">Home</a>
          <a href="/corsi-soccorritori">Soccorritori</a>
          <a href="/sensibilizzazione">Sensibilizzazione</a>
          <a href="/lezioni">Lezioni Guida</a>
          <a href="/moto">Corsi Moto</a>
          <a href="https://wa.me/+41774442999">
            <Button
              variant="contained"
              className={'whatsapp whatsrapp'}
              size="large"
              style={{ marginRight: '0 !important'}}
            >  <img
                style={{ maxWidth: '24px', marginRight: '10px'}}
                src={chat_image}
                alt={'chat'}
            />
              Contattaci
            </Button>
          </a>
        </div>
      </div>

      {props.img === 'video' ?
        <header className={'fit-margin'}>
          <a href={'/'}>
            <img className={'logo-mobile'} src={logo} alt={'test'}/>v
          </a>
          <div ref={iconRef}  className={'menu-icon-mobile'} onClick={(e) => openNav(e)}>
            <MenuIcon type={'button'} />
          </div>
          <video id={'video_background'} autoPlay={true} muted loop className={'header-video'}>
            <source src={'https://res.cloudinary.com/napolinews-it/video/upload/v1687131741/movie_2_rkvsrg.mp4'}
                    type="video/mp4"/>
          </video>
        </header>
        :
        <header>
          <div ref={iconRef}  className={'menu-icon-mobile'} onClick={(e) => openNav(e)}>
            <MenuIcon type={'button'} />
          </div>
        </header>
      }
    </>
  );
}
