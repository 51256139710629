import * as React from 'react';

export default function CtaButton() {
  return (
    <>
      <button className={'ctaButton'}>
        Iscriviti
      </button>
    </>
  );
}